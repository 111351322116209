import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "mdx-jsruntime"
    }}><inlineCode parentName="h1">{`@mdx-js/runtime`}</inlineCode></h1>
    <p><a parentName="p" {...{
        "href": "https://travis-ci.org/mdx-js/mdx"
      }}><img parentName="a" {...{
          "src": "https://travis-ci.org/mdx-js/mdx.svg?branch=master",
          "alt": "Build Status"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://lernajs.io/"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/maintained%20with-lerna-cc00ff.svg",
          "alt": "lerna"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://spectrum.chat/mdx"
      }}><img parentName="a" {...{
          "src": "https://withspectrum.github.io/badge/badge.svg",
          "alt": "Join the community on Spectrum"
        }}></img></a></p>
    <p>{`Parse and render `}<a parentName="p" {...{
        "href": "https://github.com/mdx-js/mdx"
      }}>{`MDX`}</a>{` in a runtime environment.`}</p>
    <blockquote>
      <p parentName="blockquote">{`⚠️ `}<strong parentName="p">{`warning`}</strong>{`: this is not the preferred way to use MDX since it
introduces a substantial amount of overhead and dramatically increases
bundle sizes.
It should also not be used with user input that isn’t sandboxed.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p><a parentName="p" {...{
        "href": "https://docs.npmjs.com/cli/install"
      }}>{`npm`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm i -S @mdx-js/runtime
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'
import MDX from '@mdx-js/runtime'

// Provide custom components for markdown elements
const components = {
  h1: props => <h1 style={{ color: 'tomato' }} {...props} />
}

// Provide custom components that will be referenced as JSX
// in the markdown string
const scope = {
  Demo: props => <h1>This is a demo component</h1>
}

const mdx = \`
# Hello, world!

<Demo />
\`

export default () => (
  <MDX components={components} scope={scope}>{mdx}</MDX>
)
`}</code></pre>
    <h2 {...{
      "id": "contribute"
    }}>{`Contribute`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://github.com/mdx-js/mdx/blob/master/contributing.md"
      }}><inlineCode parentName="a">{`contributing.md`}</inlineCode>{` in `}<inlineCode parentName="a">{`mdx-js/mdx`}</inlineCode></a>{` for ways to get started.`}</p>
    <p>{`This organisation has a `}<a parentName="p" {...{
        "href": "https://github.com/mdx-js/mdx/blob/master/code-of-conduct.md"
      }}>{`Code of Conduct`}</a>{`.
By interacting with this repository, organisation, or community you agree to
abide by its terms.`}</p>
    <h2 {...{
      "id": "license"
    }}>{`License`}</h2>
    <p><a parentName="p" {...{
        "href": "license"
      }}>{`MIT`}</a>{` © `}<a parentName="p" {...{
        "href": "https://compositor.io"
      }}>{`Compositor`}</a>{` and `}<a parentName="p" {...{
        "href": "https://zeit.co"
      }}>{`ZEIT`}</a></p>
    {
      /* Definitions */
    }

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      