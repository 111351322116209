import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Doc from '@mdx-js/runtime/readme.md';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>

    {
      /*
      TODO: add a nicer, smaller intro to the runtime: when to use it, when not to.
      We can also move it to `api` maybe?
      */
    }
    <Doc />

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      